







import { Vue, Component } from 'vue-property-decorator';
import { DictItem, getDictConfigByCode } from '@/api/other';
@Component({
  name: ''
})
export default class extends Vue {
  news = {} as DictItem;

  async getNews(itemCode: string) {
    const { payload } = await getDictConfigByCode('NEWS', itemCode);
    this.news = payload;
    this.news.itemContent = this.news.itemContent.replaceAll(/pre/g, 'div');
    document.title = payload.itemName;
  }

  created() {
    const itemCode = this.$route.params.code as string;
    this.getNews(itemCode);
  }
}
